.react-calendar {
	border: none;
	font: inherit;
}

.react-calendar__tile {
	aspect-ratio: 1 / 1;
}

.react-calendar__tile:disabled {
	background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #e6e6e6;
}
.react-calendar__tile--hasActive,
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #f77d04;
	color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
}

.react-daterange-picker__wrapper {
	border: none;
	font: inherit;
}

.react-daterange-picker__inputGroup {
	justify-content: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border-color: rgb(222, 224, 229);
	color: rgb(131, 131, 131);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	/* min-width: 100px;
    height: 25px; */
	margin-left: 15px;
	margin-right: 15px;
	padding: 2px;
	padding-right: 10px;
	padding-left: 10px;
}

.react-daterange-picker__range-divider {
	justify-content: center;
	display: flex;
	align-items: center;
	color: rgb(131, 131, 131);
	padding: 2px;
	padding-right: 10px;
	padding-left: 10px;
}

.react-calendar__tile--now {
	background-color: unset;
	display: flex;
	justify-content: center;
	align-items: center;
}

.react-calendar__tile--now abbr {
	border: 1px solid black;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	aspect-ratio: 1 / 1;
}


.react-calendar__tile--now.react-calendar__tile--active,
.react-calendar__tile--now.react-calendar__tile--hasActive{
	background: #f77d04;
}

.react-calendar__tile--now.react-calendar__tile--active abbr,
.react-calendar__tile--now.react-calendar__tile--hasActive abbr {
	border: none;
}